<template>
	<div class="content">
		<el-select v-model="children.childrenName" placeholder="请选择" size="small">
			<el-option v-for="item in children" :key="item.id" :label="item.name" :value="item.name"
				@click.native="mapTree(item)">
			</el-option>
		</el-select>
		<choose :children="Children" v-if="Children.length > 0"></choose>
	</div>
</template>

<script>
	import Bus from '../../bus.js';
	export default {
		props: {
			children: {
				type: Array,
			},
		},
		data() {
			return {
				Children: []
			}
		},
		methods: {
			mapTree(data) {
				if (data.childCate.length > 0) {
					this.Children = [
						...data.childCate
					];
				} else {
					Bus.$emit('childEvent', data)
				}
			},
		}
	}
</script>

<style scoped lang="less">
	.content {
		display: flex;
		flex-wrap: wrap;
		// border:1px solid black;
		.el-select {
			margin-right: 20px;
			margin-left: 0px;
		}
	}
</style>
