
export default {
	data() {
		return {
			w: 0,
			h: 0,
			left: 0,
			Show: false
		}
	},
	mounted() {
		//获取浏览器可视区域高度
		this.$nextTick(() => {
			(this as any).w = window.innerWidth || document.documentElement.clientWidth ||
				document.body.clientWidth;
			(this as any).h = window.innerHeight || document.documentElement.clientHeight ||
				document.body.clientHeight;
			//动态获取居中结果
			(this as any).calculate();
			(this as any).$store.commit('watch_w_h', {
				w: (this as any).w,
				h: (this as any).h
			});
			(this as any).onresize();
		});

		//验证是否在登录页
		this.$router.afterEach((to, from, next) => {
			this.isLogin(to);
		});

		this.$store.commit('Power_admin', {
			Powers: localStorage.getItem('role_power')
		});
	},
	methods: {
		//不是登录页隐藏其他路由界面
		isLogin<T>(to: T): T {
			const th_is = this;
			class obje {
				public isShow: boolean;
				public constructor(to: Object) {
					to.name == 'login' ? this.isShow = false : this.isShow = true;
					return this.isShow;
				}
			}
			this.Show = new obje(to).isShow;
		},
		//监听浏览器可视宽高
		onresize(): void {
			const th_is = this;
			class monitor {
				constructor() {
					window.onresize = () => {
						this.w_h(document.body.clientWidth, document.body.clientHeight);
					};
				}
				w_h(width: number, height: number) {
					(th_is as any).w = width;
					(th_is as any).h = height;
					(th_is as any).calculate();
					(th_is as any).$store.commit('watch_w_h', {
						w: (th_is as any).w,
						h: (th_is as any).h
					});
				}
			}
			new monitor();
		},
		//动态获取居中结果
		calculate<T>(): T {
			const th_is = this;
			class size {
				private result: number;
				constructor() {
					this.result = ((th_is as any).w - parseInt((th_is as any).w * 10 / 100)) * 95 / 100;
					(th_is as any).left = parseInt((th_is as any).w * 10 / 100) + ((th_is as any).w - parseInt(
						(th_is as any).w * 1 / 100)) * 1 / 100;
					// console.log("页面宽度为" + this.result);
					// console.log("距离左边导航栏为" + (th_is as any).left);
					return this.result;
				}
			}
			return new size().result;
		}
	}
}
