import ElementUI from 'element-ui';
import vueMain from '@/main.ts';
const axios = require('axios');

//https://cms.smartcitytx.cn 正式环境
//https://smartcity.shenzhenbenwo.com 测试环境
// axios.defaults.baseURL = 'http://cms.smartcitytx.cn';
// 请求响应超时时间
axios.defaults.timeout = 10000;
// 请求响应前拦截器
axios.interceptors.request.use(function(config) {
	config.headers['X-Access-Token'] = localStorage.getItem('GETTOKEN') || '';
	return config;
}, function(error) {
	return Promise.reject(error);
});

// 请求响应后拦截器
axios.interceptors.response.use(function(response) {
	switch (response.data.status || response.data.code || response.config.responseType) {
		case 200:
			return response;
			break;
		case 401001: //登录过期
			ElementUI.Message.error('用户信息已过期，请重新登录！');
			localStorage.removeItem('GETTOKEN');
			vueMain._router.push({
				path: '/',
				query: {}
			});
			break;
		case "blob": //二进制文本
			return response.data;
			break;
		default:
			ElementUI.Message.error(response.data.msg);
			ElementUI.Loading.service().close()
			return response;
	}
}, function(error) {
	console.log("报错执行-------------------》");
	console.log(error.message);
	if (error.message == 'timeout of 10000ms exceeded') {
		ElementUI.Message.error('请求超时，请稍后重试！');
	} else {
		ElementUI.Message.error(error.response.data.msg);
		ElementUI.Loading.service().close()
	}
	return Promise.reject(error);
});



export default {
	get: function(path = '', data = {}) {
		return new Promise(function(resolve, reject) {
			axios.get(path, {
					params: data
				})
				.then(function(response) {
					resolve(response.data);
				})
				.catch(function(error) {
					reject(error);
				});
		});
	},
	post: function(path = '', data = {}) {
		return new Promise(function(resolve, reject) {
			axios.post(path, data)
				.then(function(response) {
					resolve(response.data);
				})
				.catch(function(error) {
					reject(error);
				});
		});
	}
};
