import request from './request.js';

/* 
登录部分
 */

//校验验证码
export const checkCaptcha = (params) => request.post('/api/blade-auth/oauth/checkCaptcha', params);

//获取验证码
export const captcha = (params) => request.get('/api/blade-auth/oauth/captcha', params);

//登录
export const login = (params) => request.post('/api/blade-auth/oauth/pcLogin', params);

//退出登录
export const logout = (params) => request.get('/api/blade-auth/oauth/pcLogout', params);

//获取用户信息
export const getUserInfo = (params) => request.get('/api/blade-auth/oauth/getUserInfo', params);

//获取手机验证码
export const sendSms = (params) => request.post('/api/blade-auth/oauth/sendSms', params);

/* 
功能性部分
*/

//项目列表
export const projectPage = (params) => request.post('/api/mode/project/projectPage', params);

//导出项目列表
export const exportProject = (params) => request.post('/api/mode/project/exportProject', params);

//工时列表
export const hours_list = (params) => request.post('/api/mode/project-member-work/list', params);

//工时详情
export const hours_detail = (params) => request.get('/api/mode/project-member-work/detail', params);

//修改工时信息
export const updateWorkHour = (params) => request.post('/api/mode/project-member-work/updateWorkHour', params);

//导出工时列表
export const exportWorkHour = (params) => request.post('/api/mode/project-member-work/exportWorkHour', params);

//工时列表批量审核通过
export const auditAgreeBatch = (params) => request.get('/api/mode/project-member-work/auditAgreeBatch', params);

//用户列表
export const pageList = (params) => request.post('/api/mode/user/pageList', params);

//用户列表新增
export const createUser = (params) => request.post('/api/mode/user/createUser', params);

//用户列表删除
export const remove = (params) => request.get('/api/mode/user/remove', params);

//用户列表禁用
export const disabledUser = (params) => request.get('/api/mode/user/disabledUser', params);

//用户列表启用
export const enableUser = (params) => request.get('/api/mode/user/enableUser', params);

//用户列表修改
export const updateUser = (params) => request.post('/api/mode/user/updateUser', params);

//用户列表详情
export const detail = (params) => request.get('/api/mode/user/detail', params);

//角色列表
export const role_list = (params) => request.post('/api/mode/role/list', params);

//角色列表详情
export const role_detail = (params) => request.get('/api/mode/role/detail', params);

//角色列表删除
export const role_remove = (params) => request.get('/api/mode/role/remove', params);

//角色列表新增
export const saveRole = (params) => request.post('/api/mode/role/saveRole', params);

//角色列表修改
export const updateRole = (params) => request.post('/api/mode/role/updateRole', params);

//角色列表下拉
export const role_select = (params) => request.get('/api/mode/role/select', params);

//组织架构
export const blade_tree = (params) => request.get('/api/mode/blade-dept/tree', params);

//组织架构删除
export const tree_remove = (params) => request.get('/api/mode/blade-dept/remove', params);

//组织架构新增
export const tree_add = (params) => request.post('/api/mode/blade-dept/saveDept', params);

//组织架构修改
export const tree_edit = (params) => request.post('/api/mode/blade-dept/updateDept', params);

//组织架构详情
export const tree_detail = (params) => request.get('/api/mode/blade-dept/detail', params);

//菜单权限配置列表
export const getMenuTree = (params) => request.get('/api/mode/blade_menu/getMenuTree', params);

//提交权限配置
export const editPermissions = (params) => request.post('/api/mode/blade_menu/editPermissions', params);

//获取字典
export const getDictList = (params) => request.get('/api/blade_shopmall/common/getDictList', params);

//用户管理下拉接口
export const userSelect = (params) => request.get('/api/mode/user/select', params);

//项目阶段树
export const projectStageTree = (params) => request.get('/api/mode/project/stageTree', params);

//项目详情
export const projectDetail = (params) => request.get('/api/mode/project/projectDetail', params);

//审核记录
export const projectAudit = (params) => request.get(`/api/mode/project-audit-record/projectAuditList/${params}`);

//节点审核通过/驳回
export const nodeAudit = (params) => request.post('/api/mode/project/nodeAudit', params);

//下一节点
export const projectNextNode = (params) => request.post('/api/mode/project/nextNode', params);

//删除项目
export const delProject = (params) => request.post(`/api/mode/project/delProject/${params}`);

//提交废止
export const projectAbolition = (params) => request.post('/api/mode/project/projectAbolition', params);

//提交挂起
export const projectHands = (params) => request.post('/api/mode/project/projectHands', params);

//挂起初审
export const handsAudit = (params) => request.post('/api/mode/project/handsAudit', params);

//挂起复审
export const handsReview = (params) => request.post('/api/mode/project/handsReview', params);

//取消挂起
export const handsCancel = (params) => request.post(`/api/mode/project/handsCancel/${params}`);

//废止审核
export const abolitionAudit = (params) => request.post('/api/mode/project/abolitionAudit', params);

//废止审核
export const addProject = (params) => request.post('/api/mode/project/addProject', params);

//修改项目
export const editProject = (params) => request.post('/api/mode/project/editProject', params);

//项目成员
export const projectMember = (params) => request.get(`/api/mode/project-member/projectMemberList/${params}`);

//编辑项目成员
export const editProjectMember = (params) => request.post('/api/mode/project-member/editProjectMember', params);

//修改密码
export const updatePassword = (params) => request.post('/api/blade-auth/updatePassword', params);

//工时单个审核通过
export const auditAgree = (params) => request.post('/api/mode/project-member-work/auditAgree', params);

//工时单个审核驳回
export const auditReject = (params) => request.get('/api/mode/project-member-work/auditReject', params);

// 重置面貌
export const resetPassword = (params) => request.get('/api/mode/user/resetPasswords', params);

//导出文件
export const exportXlsx = (params) => request.post('/api/blade_shopmall/common/export', params);
