import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './utils/pxtorem';
import './utils/element-ui.js'
import 'element-ui/lib/theme-chalk/index.css';
import { sha256 } from 'js-sha256';
import './style/index.css'
import { beforeUploadSize } from '@/utils/upload'

const Bus = require('./bus.js');
const outToExcel = require('./utils/outToExcel.js');
// const Xlsx_download = require('./common/xlsx_download.js');
const md5 = require('blueimp-md5');
const CryptoJS = require('crypto-js');
const axios = require('axios');
const Avue = require('@smallwei/avue');
const AvueUeditor = require('avue-plugin-ueditor');
import '@smallwei/avue/lib/index.css';

//全局组件注册
import hader_nav from './components/hader_nav/hader_nav.vue';
import left_nav from './components/left_nav/left_nav.vue';
import crumbs_nav from './components/crumbs_nav/crumbs_nav.vue';
import choose from './components/choose/choose.vue';
import add_Projects from './components/add_Projects/add_Projects.vue'
import Projects_details from './components/Projects_details/Projects_details.vue'
import Projects_audit from './components/Projects_audit/Projects_audit.vue'
import Projects_NextNode from './components/Projects_NextNode/Projects_NextNode.vue'
import Projects_Application from './components/Projects_Application/Projects_Application.vue'
import Projects_Amend from './components/Projects_Amend/Projects_Amend.vue'

Vue.component('Haders', hader_nav);
Vue.component('letMenu', left_nav);
Vue.component('crumbs_nav', crumbs_nav);
Vue.component('choose', choose);
Vue.component('add_Projects', add_Projects);
Vue.component('Projects_details', Projects_details);
Vue.component('Projects_audit', Projects_audit);
Vue.component('Projects_NextNode', Projects_NextNode);
Vue.component('Projects_Application', Projects_Application);
Vue.component('Projects_Amend', Projects_Amend);

Vue.use(md5);
Vue.use(CryptoJS);
Vue.use(Avue);
Vue.use(AvueUeditor);

Vue.prototype.bus = new Vue();
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
Vue.prototype.$outToExcel = outToExcel;
// Vue.prototype.$Xlsx_download = Xlsx_download;
Vue.prototype.$color = '0, 0, 0, 0.7';
Vue.prototype.$beforeUploadSize = beforeUploadSize;

// router.beforeEach((to, from, next) => {
// 	console.log("开始判断用户是否登录--------------------");
// 	const token = localStorage.getItem('GETTOKEN');
// 	if (to.matched.some(record => record.meta.requireAuth || record.meta.homePages)) { //requireAuth为true则该路由需要登录
// 		if (token) {
// 			next();
// 		} else {
// 			next({
// 				path: '/',
// 				query: {
// 					redirect: to.fullPath
// 				}
// 			});
// 		}
// 	} else {
// 		next();
// 	}
// });

const vueMain = new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');

export default vueMain;