const projectRules = {
    projectCode: [
        {
            required: true,
            message: "请输入项目编号",
            trigger: "blur",
        },
    ],
    projectName: [
        {
            required: true,
            message: "请输入项目名称",
            trigger: "blur",
        },
    ],
    type: [
        {
            required: true,
            message: "请输入项目类别",
            trigger: "blur",
        },
    ],
    customerName: [
        {
            required: true,
            message: "请输入客户名称",
            trigger: "blur",
        },
    ],
    customerManagerName: [
        {
            required: true,
            message: "请输入客户经理",
            trigger: "blur",
        },
    ],
    schemeManagerName: [
        {
            required: true,
            message: "请输入方案经理",
            trigger: "blur",
        },
    ],
    projectManagerName: [
        {
            required: true,
            message: "请输入项目经理",
            trigger: "blur",
        },
    ],
    startTime: [
        {
            required: true,
            message: "请选择起始日期",
            trigger: "blur",
        },
    ],
    endTime: [
        {
            required: true,
            message: "请选择结束日期",
            trigger: "blur",
        },
    ],
    customerManager: [
        {
            required: true,
            message: "请选择客户经理",
            trigger: "blur",
        },
    ],
    schemeManager: [
        {
            required: true,
            message: "请选择方案经理",
            trigger: "blur",
        },
    ],
    projectManager: [
        {
            required: true,
            message: "请选择项目经理",
            trigger: "blur",
        },
    ],
    projectDescribe: [
        {
            required: true,
            message: "请输入项目简介",
            trigger: "blur",
        },
    ],
    yutouru: [
        {
            required: true,
            message: "请选择是否预投入",
            trigger: "blur",
        },
    ],
    nodeUrl: [
        {
            required: true,
            message: "请上传节点附件",
            trigger: "blur",
        },
    ],
    projectedCost: [
        {
            required: true,
            message: "请输入成本预算",
            trigger: "blur",
        },
    ],
    laborCost: [
        {
            required: true,
            message: "请输入实际人工成本",
            trigger: "blur",
        },
    ],
    actualOutlay: [
        {
            required: true,
            message: "请输入实际支出",
            trigger: "blur",
        },
    ],
    income: [
        {
            required: true,
            message: "请输入收入",
            trigger: "blur",
        },
    ],
    contractAmount: [
        {
            required: true,
            message: "请输入合同额",
            trigger: "blur",
        },
    ],
    projectedUrl: [
        {
            required: true,
            message: "请上传概(预)算表",
            trigger: "blur",
        },
    ],
    turnOverDate: [
        {
            required: true,
            message: "请选择移交时间",
            trigger: "blur",
        },
    ],
    projectStageName: [
        {
            required: true,
            message: "请选择当前阶段",
            trigger: "blur",
        },
    ],
    projectNodeName: [
        {
            required: true,
            message: "请选择当前节点",
            trigger: "blur",
        },
    ],
    toatlHour: [
        {
            required: true,
            message: "请输入累计工时",
            trigger: "blur",
        },
    ],
    projectProgress: [
        {
            required: true,
            message: "请选择进度",
            trigger: "blur",
        },
    ],
    cost: [
        {
            required: true,
            message: "请输入成本",
            trigger: "blur",
        },
    ],
    risk: [
        {
            required: true,
            message: "请输入存在风险",
            trigger: "blur",
        },
    ],
    statement: [
        {
            required: true,
            message: "请上传决算表",
            trigger: "blur",
        },
    ],
    receivedAmount: [
        {
            required: true,
            message: "请输入收款",
            trigger: "blur",
        },
    ]
};

export { projectRules };