import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		w: 0,
		h: 0,
		token: '',
		Powers: [],//权限列表
	},
	getters: {
		breadListState() {
			// console.log(sessionStorage.getItem('breadListStorage'));
			// var reg:any = JSON.parse(sessionStorage.getItem('breadListStorage')) || [];
			// return reg;
		}
	},
	mutations: {
		watch_w_h(state, value) {
			// console.log("开始存储页面宽高:");
			state.w = value.w;
			state.h = value.h;
		},
		GETTOKEN(state, value) {
			state.token = value.token;
		},
		breadListMutations(getters, list) {
			// getters.breadListState = list;
			sessionStorage.setItem('breadListStorage', list);
		},
		Power_admin(state, value) {
			state.Powers = value.Powers;
		}
	},
	actions: {
	},
	modules: {
	}
})
