
	import {
		getUserInfo,
		logout
	} from '../../https/api.js';
	export default {
		data() {
			return {
				user_info: {}
			}
		},
		mounted() {
			this.user_info = JSON.parse(localStorage.getItem('user_info')) || '';
		},
		methods: {
			open() {
				const TH_IS = this;
				this.$confirm('是否退出当前账号？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					TH_IS.logout();
				}).catch(() => {

				});
			},
			async logout() {
				try {
					// const res = await logout();
					// if (res.code == 200 || res.code == '200') {

					// }
					this.$message({
						message: '退出成功！',
						type: 'success'
					});
					this.$router.push({
						path: '/'
					});
					localStorage.removeItem('GETTOKEN');
				} catch (e) {
					//TODO handle the exception
					console.log(e);
				}
			}
		}
	}
