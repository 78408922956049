import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
	{
		path: '/',
		name: 'login',
		component: () => import('../views/LoginView/LoginView.vue')
	},
	{
		path: '/home',
		name: 'home',
		meta: {
			title: '首页',
			requireAuth: true
		},
		component: () => import('../views/HomeView/HomeView.vue')
	},
	{
		path: '/Projects',
		name: 'Projects',
		meta: {
			title: '项目管理',
			requireAuth: true
		},
		component: () => import('../views/Menu_view/Projects/Projects.vue')
	},
	{
		path: '/Hours',
		name: 'Hours',
		meta: {
			title: '工时管理',
			requireAuth: true
		},
		component: () => import('../views/Menu_view/Hours/Hours.vue')
	}, {
		path: '/Role',
		name: 'Role',
		meta: {
			title: '角色管理',
			requireAuth: true
		},
		component: () => import('../views/Menu_view/Role/Role.vue')
	}, {
		path: '/User',
		name: 'User',
		meta: {
			title: '用户管理',
			requireAuth: true
		},
		component: () => import('../views/Menu_view/User/User.vue')
	}, {
		path: '/modify_password',
		name: 'modify_password',
		meta: {
			title: '修改密码',
			requireAuth: true
		},
		component: () => import('../views/Menu_view/modify_password/modify_password.vue')
	},{
		path: '/No_power',
		name: 'No_power',
		meta: {
			title: '暂无权限',
			requireAuth: true
		},
		component: () => import('../views/No_power/No_power.vue')
	},
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

export default router
