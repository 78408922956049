<template>
	<div class="content">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item v-for="(item,index) in breadList" :key="index" :to="{ path: item.path }">
				{{item.meta.title}}
			</el-breadcrumb-item>
		</el-breadcrumb>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				breadList: [] // 路由集合
			};
		},
		watch: {
			$route() {
				this.getBreadcrumb();
			}
		},
		methods: {
			isHome(route) {
				return route.name === "product";
			},
			getBreadcrumb() {
				let matched = this.$route.matched;
				if (matched[0].name == "product" || matched[0].name == "commodity" || matched[0].name ==
					"attribute") {
					matched = [{
						path: "/product",
						meta: {
							title: "商品管理"
						}
					}].concat(matched);
				} else {
					// matched = [{
					// 	path: "/order",
					// 	meta: {
					// 		title: "订单管理"
					// 	}
					// }].concat(matched);
				}
				this.breadList = matched;
			}
		},
		created() {
			this.getBreadcrumb();
		}
	};
</script>

<style lang="less" scoped>
	.content {
		// background-color: #ffffff;
		padding-left: 20px;
	}
</style>
