
	export default {
		data() {
			return {
				w: 0,
				h: 0,
				index: localStorage.getItem('active_menu') || 1
			}
		},
		mounted() {
			this.h = document.documentElement.clientHeight;
			(this as any).onresize();
		},
		methods: {
			onresize(): void {
				const th_is = this;
				class monitor {
					constructor() {
						window.onresize = () => {
							this.w_h(document.body.clientWidth, document.documentElement.clientHeight);
						};
					}
					w_h(width: number, height: number) {
						th_is.h = height;
					}
				}
				new monitor();
			},
			handleOpen(key: any, keyPath: any) {
				console.log("执行1");
				localStorage.setItem('active_menu', key);
			}
		},
	}
