<template>
	<div class="content">
		<el-form :rules="rules" :model="req" ref="ruleForm" label-position="right" label-width="auto">
			<h3 class="title">基本情况</h3>
			<el-row :gutter="20">
				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="项目编号：" prop="projectCode">
						<el-input placeholder="项目编号" v-model="req.projectCode" clearable size="small">
						</el-input>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="项目名称：" prop="projectName">
						<el-input placeholder="项目名称" v-model="req.projectName" clearable size="small">
						</el-input>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="项目类别：" prop="type">
						<el-input placeholder="项目类别" v-model="req.type" clearable size="small">
						</el-input>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="客户名称：" prop="customerName">
						<el-input placeholder="客户名称" v-model="req.customerName" clearable size="small">
						</el-input>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="起始日期：" prop="startTime">
						<el-date-picker class="el-date-picker" size="small" v-model="req.startTime" type="date"
							placeholder="年/月/日" value-format="yyyy-MM-dd">
						</el-date-picker>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="结束日期：" prop="endTime">
						<el-date-picker class="el-date-picker" size="small" v-model="req.endTime" type="date"
							placeholder="年/月/日" value-format="yyyy-MM-dd">
						</el-date-picker>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="客户经理：" prop="customerManager">
						<el-select v-model="req.customerManager" placeholder="客户经理" clearable>
							<el-option v-for="item in userSelectList" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="方案经理：" prop="schemeManager">
						<el-select v-model="req.schemeManager" placeholder="方案经理" size="small" clearable>
							<el-option v-for="item in userSelectList" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="项目经理：" prop="projectManager">
						<el-select v-model="req.projectManager" placeholder="项目经理" size="small" clearable>
							<el-option v-for="item in userSelectList" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>

				<el-col :lg="6" :span="8" v-if="[5, 6, 7, 8, 9, 10].includes(projectNode)">
					<el-form-item class="w-full" label="移交时间：" prop="turnOverDate">
						<el-date-picker class="el-date-picker" v-model="req.turnOverDate" type="date" size="small"
							placeholder="年/月/日" value-format="yyyy-MM-dd">
						</el-date-picker>
					</el-form-item>
				</el-col>

				<el-col :span="24">
					<el-form-item class="w-full" label="项目简介：" prop="projectDescribe">
						<el-input type="textarea" style="width: 90%;" :rows="3" placeholder="" disabled
							v-model="req.projectDescribe">
						</el-input>
					</el-form-item>
				</el-col>

			</el-row>

			<h3 class="title">进度管理</h3>
			<el-row>
				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="是否预投入：" prop="yutouru">
						<el-select v-model="req.yutouru" placeholder="请选择" size="small"
							:disabled="[0, 5].indexOf(projectNode) == -1">
							<el-option v-for="item in yutouruOption" :key="item.value" :label="item.text"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="当前阶段：" prop="projectStageName">
						<el-select v-model="req.projectStageName" placeholder="请选择" size="small" disabled>
							<el-option v-for="item in options" :key="item.value" :label="item.text" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="当前节点：" prop="projectNodeName">
						<el-select v-model="req.projectNodeName" placeholder="请选择" size="small" disabled>
							<el-option v-for="item in options" :key="item.value" :label="item.text" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="累计工时：" prop="toatlHour">
						<el-input type="number" :min="0" maxlength="20" :rows="2" placeholder="请输入内容"
							v-model="req.toatlHour" disabled size="small">
							<template slot="append">元</template>
						</el-input>
					</el-form-item>
				</el-col>

				<el-col :lg="18" :span="24">
					<el-form-item class="w-full" label="节点附件：" prop="nodeUrl">
						<el-upload class="upload-demo" style="display: flex;align-items: center;"
							action="/api/blade_shopmall/common/upload" :on-change="handleChangeNode"
							:on-preview="handlePreview" :limit='5' :on-exceed='astrict' :headers="headers"
							:file-list="nodeUrl" :on-remove="nodeRemove" :before-upload="$beforeUploadSize">
							<el-button size="small" style="width: 80px;" type="primary">点击上传</el-button>
						</el-upload>
					</el-form-item>
				</el-col>
			</el-row>

			<h3 class="title">成本管理</h3>
			<el-row>
				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="成本预算：" prop="projectedCost">
						<el-input type="text" oninput="value=value.match(/\d+\.?\d{0,4}/)" :min="0" maxlength="20"
							placeholder="请输入内容" v-model="req.projectedCost" size="small">
							<template slot="append">元</template>
						</el-input>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="实际人工成本：" >
						<el-input type="number" :min="0" maxlength="20" placeholder="请输入内容" v-model="req.laborCost"
							disabled size="small">
							<template slot="append">元</template>
						</el-input>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="实际支出：" prop="actualOutlay">
						<el-input type="text" oninput="value=value.match(/\d+\.?\d{0,4}/)" :min="0" maxlength="20"
							placeholder="请输入内容" v-model="req.actualOutlay" size="small">
							<template slot="append">元</template>
						</el-input>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="收入：" prop="income">
						<el-input type="text" oninput="value=value.match(/\d+\.?\d{0,4}/)" :min="0" maxlength="20"
							placeholder="请输入内容" v-model="req.income" size="small">
							<template slot="append">元</template>
						</el-input>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="合同额：" prop="contractAmount">
						<el-input type="text" oninput="value=value.match(/\d+\.?\d{0,4}/)" maxlength="20" :min="0"
							placeholder="请输入内容" v-model="req.contractAmount" size="small">
							<template slot="append">元</template>
						</el-input>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="毛利：">
						<el-input type="number" maxlength="20" :min="0" placeholder="请输入内容" v-model="req.grossProfit"
							disabled size="small">
							<template slot="append">元</template>
						</el-input>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="实际毛利：">
						<el-input type="number" :min="0" maxlength="20" placeholder="请输入内容"
							v-model="req.actualGrossProfit" size="small" disabled>
							<template slot="append">元</template>
						</el-input>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="收款：" prop="receivedAmount">
						<el-input type="text" oninput="value=value.match(/\d+\.?\d{0,4}/)" :min="0" maxlength="20"
							placeholder="请输入内容" v-model="req.receivedAmount" size="small">
							<template slot="append">元</template>
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row>
				<el-col :lg="12" :span="24">
					<el-form-item class="w-full" label="概(预)算表：" prop="projectedUrl">
						<el-upload class="upload-demo" style="display: flex;align-items: center;"
							action="/api/blade_shopmall/common/upload" :on-change="handleChangeProjected"
							:on-preview="handlePreview" :headers="headers" :limit='1' :on-exceed='astrict'
							:file-list="projectedUrl" :on-remove="projectedRemove" :before-upload="$beforeUploadSize">
							<el-button size="small" type="primary">点击上传</el-button>
						</el-upload>
					</el-form-item>
				</el-col>

				<el-col :lg="12" :span="24">
					<el-form-item class="w-full" label="决算表：" prop="statement">
						<el-upload class="upload-demo" style="display: flex;align-items: center;"
							action="/api/blade_shopmall/common/upload" :on-change="handleChangeStatement"
							:on-preview="handlePreview" :headers="headers" :limit='1' :on-exceed='astrict'
							:file-list="statement" :on-remove="statementRemove" :before-upload="$beforeUploadSize">
							<el-button size="small" style="width: 80px;" type="primary">点击上传</el-button>
						</el-upload>
					</el-form-item>
				</el-col>
			</el-row>

			<h3 class="title">整体评价</h3>
			<el-row>
				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="进度：" prop="projectProgress">
						<el-select v-model="req.projectProgress" placeholder="请选择" size="small">
							<el-option v-for="item in projectProgressOption" :key="item.value" :label="item.text"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="成本：" >
						<el-input type="text" placeholder="" disabled v-model="req.cost" size="small">
							<template slot="append">元</template>
						</el-input>
					</el-form-item>
				</el-col>

				<el-col :span="24">
					<el-form-item class="w-full" label="存在风险：" >
						<el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="req.risk">
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="$emit('closeDialogAmend', false)">取 消</el-button>
			<el-button type="primary" @click="editProject()">确 定</el-button>
		</span>
	</div>
</template>

<script>
import { projectRules } from '@/utils/projectsRules.js'
import { getDictList, userSelect, editProject, projectDetail } from '../../https/api.js';
export default {
	data() {
		return {
			rules: projectRules,
			req: {},
			headers: {},
			token: "",
			categoryProjectList: [], //项目类别
			textarea: '',
			userSelectList: [], //用户下拉
			nodeUrl: [], //节点附件
			projectedUrl: [], //预算表url
			statement: [], //决算表
			yutouruOption: [ //是否预算配置
				{
					value: 1,
					text: '是'
				},
				{
					value: 0,
					text: '否'
				}
			],
			projectProgressOption: [{
				value: 1,
				text: '正常'
			},
			{
				value: 2,
				text: '风险'
			},
			{
				value: 3,
				text: '滞后'
			}
			],
			options: [],
			projectNode: 0,
		}
	},
	props: {
		parameter: {
			type: [Object, Array],
		}
	},
	watch: {
		'req.income': function (value) { //收入
			this.calculation(2);
			this.calculation(3);
		},
		'req.projectedCost': function (value) { //成本预算
			this.calculation(2);
		},
		'req.actualOutlay': function (value) {
			this.calculation(3);
		},
		'req.toatlHour': function (value) {
			this.calculation(1);
		},
	},
	mounted() {
		this.token = localStorage.getItem("GETTOKEN")
		this.headers = {
			'x-access-token': this.token
		}
		this.projectNode = this.parameter.projectNode
		this.categoryProject('project_type');
		this.projectDetail();
		this.userSelect();
	},
	methods: {
		// 1.实际人工成本 累计工时 * 187.5
		// 2.毛利 收入 - 成本预算
		// 3.实际毛利 收入 - 实际支出
		calculation(type) {
			switch (type) {
				case 1:
					if (this.req.toatlHour > 0) {
						var laborCost = this.req.toatlHour * 187.5;
						this.req.laborCost = parseFloat(laborCost.toFixed(4));
					}
					break;
				case 2:
					if (this.req.income && this.req.projectedCost) {
						//毛利
						var Grossprofit = this.req.income - this.req.projectedCost;
						this.req.grossProfit = parseFloat(Grossprofit.toFixed(4));
					}
					break;
				case 3:
					if (this.req.income && this.req.actualOutlay) {
						//实际毛利
						var Actualgrossprofit = this.req.income - this.req.actualOutlay;
						this.req.actualGrossProfit = parseFloat(Actualgrossprofit.toFixed(4));
					}
			}
		},
		//项目详情
		async projectDetail() {
			const res = await projectDetail(this.parameter)
			if (res.code == 200) {
				this.req = res.data
				this.req.type = res.data.type
				res.data.projectedUrl.forEach((item, index) => {
					this.projectedUrl.push({
						name: item.fileName,
						url: item.fileUrl,
						id: item.id
					})
				})
				res.data.nodeUrl.forEach((item, index) => {
					this.nodeUrl.push({
						name: item.fileName,
						url: item.fileUrl,
						id: item.id
					})
				})
				res.data.statement.forEach((item, index) => {
					this.statement.push({
						name: item.fileName,
						url: item.fileUrl,
						id: item.id
					})
				})

			}
		},
		//获取项目类别
		async categoryProject(e) {
			const res = await getDictList({
				code: e
			})
			if (res.code == 200) {
				this.categoryProjectList = res.data
			}
		},
		//用户下拉
		async userSelect() {
			const res = await userSelect()
			if (res.code == 200) {
				this.userSelectList = res.data
			}
		},
		//修改
		async editProject() {
			const loading = this.$loading({
				lock: true,
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0)'
			});
			this.req.projectedUrl = []
			this.projectedUrl.forEach(item => {
				if (item.response) {
					this.req.projectedUrl.push(item.response.data)
				} else {
					this.req.projectedUrl.push(item.url)
				}
			})
			this.req.nodeUrl = []
			this.nodeUrl.forEach(item => {
				if (item.response) {
					this.req.nodeUrl.push(item.response.data)
				} else {
					this.req.nodeUrl.push(item.url)
				}
			})
			this.req.statement = []
			this.statement.forEach(item => {
				if (item.response) {
					this.req.statement.push(item.response.data)
				} else {
					this.req.statement.push(item.url)
				}
			})
			const res = await editProject(this.req)
			if (res.code == 200) {
				loading.close()
				this.$message.success(`修改成功！`);
				this.$emit('closeDialogAmend', false)
			} else {
				loading.close();
			}
		},
		// 上传节点附件
		handleChangeNode(file, fileList) {
			console.log(fileList)
			this.nodeUrl = fileList;
		},
		//上传预算表
		handleChangeProjected(file, fileList) {
			this.projectedUrl = fileList;
			console.log(fileList)
		},
		//上传决算表
		handleChangeStatement(file, fileList) {
			this.statement = fileList;
			console.log(fileList)
		},
		//上传移除文件
		nodeRemove(file, fileList) {
			this.nodeUrl = fileList;
		},
		//上传移除文件
		projectedRemove(file, fileList) {
			this.projectedUrl = fileList;
		},
		statementRemove(file, fileList) {
			this.statement = fileList;
		},
		postData(url, data, callback, token) {
			var xhr = new XMLHttpRequest();
			xhr.onreadystatechange = function () {
				if (xhr.readyState == 4) {
					if ((xhr.status >= 200 && xhr.status < 300) || xhr.status == 304) {
						console.log(xhr.response);
						callback && callback(xhr.response);
					}
				}
			}
			xhr.open('POST', url, true);
			xhr.responseType = 'blob';
			xhr.setRequestHeader('Content-Type', 'application/json');
			xhr.setRequestHeader('Accept', 'application/json');
			if (token) {
				xhr.setRequestHeader('X-Access-Token', token)
			}
			xhr.send(JSON.stringify(data)); // 必须是字符串形式
		},
		// 点击控制文件上传后的下载
		handlePreview(file) {
			console.log(file);
			if (file.id) {
				const th_is = this;
				var url = '/api/blade_shopmall/common/export';
				var params = {
					id: file.id
				};
				var callback = function (data) {
					console.log(data);
					if (data.size > 70) {
						let url = window.URL.createObjectURL(
							new Blob([data], {
								// type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
								// type: httpType
							})
						);
						//创建隐藏的a标签
						let link = document.createElement("a");
						link.style.display = "none";
						link.href = url;
						//设置导出文件的名字
						let excelName = `${th_is.req.projectCode}_${file.name}`;
						link.setAttribute("download", excelName);
						document.body.appendChild(link);
						//模拟点击事件
						link.click();
						//导出成功后删除这个标签并释放blob对象
						link.remove();
						window.URL.revokeObjectURL(url); //释放掉blob对象
						th_is.$message({
							message: '导出成功！',
							type: 'success'
						});
					} else {
						th_is.$message({
							message: '导出数据为空！',
							type: 'error'
						});
					}
				}
				var token = localStorage.getItem('GETTOKEN') || '';
				this.postData(url, params, callback, token);
			}
		},
		astrict() {
			this.$message.error(`超出最大可上传数量！`);
		},
	}
}
</script>

<style lang="less" scoped>
.content {
	.title {
		font-weight: 400;
		margin-bottom: 20px;
	}

	.haders_nav {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		h4 {
			font-weight: 400;
		}

		.el-input {
			width: 250px;
			// height:30px !important;
			// margin-left: 20px;
			margin-right: 20px;
		}

		.el-button {
			width: 100px;
		}
	}

	.demo-input-suffix /deep/ .el-select {
		width: 250px;
		margin-right: 20px;
	}

	.demo-input-suffix {
		margin-bottom: 20px;
		font-size: 10px;
	}

	.demo-input /deep/ .el-form-item__label {
		font-size: 10px;
	}

	.demo-input /deep/ .el-textarea {
		width: 50%;
	}

	.demo-input /deep/ .el-form-item__label {
		padding: 0px 4px 0px 0px;
	}

	.dialog-footer {
		display: flex;
		justify-content: flex-end;
	}

	::v-deep .el-upload-list__item.is-success.focusing .el-icon-close-tip {
		display: none !important;
	}
}
</style>
