<template>
	<div class="content">

		<el-form :rules="rules" :model="categoryProjectData" ref="ruleForm" label-position="right" label-width="auto">
			<h3 class="title">基本情况</h3>
			<el-row :gutter="20">
				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="项目编号：" prop="projectCode">
						<el-input placeholder="项目编号" v-model="categoryProjectData.projectCode" disabled size="small">
						</el-input>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="项目名称：" prop="projectName">
						<el-input placeholder="项目名称" v-model="categoryProjectData.projectName" disabled size="small">
						</el-input>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="项目类别：" prop="type">
						<el-input placeholder="项目类别" v-model="categoryProjectData.type" clearable disabled size="small">
						</el-input>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="客户名称：" prop="customerName">
						<el-input placeholder="客户名称" v-model="categoryProjectData.customerName" disabled size="small">
						</el-input>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="起始日期：" prop="startTime">
						<el-date-picker class="el-date-picker" v-model="categoryProjectData.startTime" disabled
							size="small" type="date" placeholder="年/月/日">
						</el-date-picker>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="结束日期：" prop="endTime">
						<el-date-picker class="el-date-picker" v-model="categoryProjectData.endTime" disabled
							size="small" type="date" placeholder="年/月/日">
						</el-date-picker>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="客户经理：" prop="customerManagerName">
						<el-select v-model="categoryProjectData.customerManagerName" placeholder="客户经理" size="small"
							disabled>
							<el-option v-for="item in options" :key="item.dictKey" :label="item.dictValue"
								:value="item.dictKey">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="方案经理：" prop="schemeManagerName">
						<el-select v-model="categoryProjectData.schemeManagerName" placeholder="方案经理" size="small"
							disabled>
							<el-option v-for="item in options" :key="item.dictKey" :label="item.dictValue"
								:value="item.dictKey">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="项目经理：" prop="projectManagerName">
						<el-select v-model="categoryProjectData.projectManagerName" placeholder="项目经理" size="small"
							disabled>
							<el-option v-for="item in options" :key="item.dictKey" :label="item.dictValue"
								:value="item.dictKey">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6" v-if="[7, 8, 9, 10].includes(tabsIndex)">
					<el-form-item class="w-full" label="移交时间：" prop="turnOverDate">
						<el-date-picker class="el-date-picker" v-model="categoryProjectData.turnOverDate" disabled
							size="small" type="date" placeholder="年/月/日" value-format="yyyy-MM-dd">
						</el-date-picker>
					</el-form-item>
				</el-col>

				<el-col :span="24">
					<el-form-item class="w-full" label="项目简介：" prop="projectDescribe">
						<el-input type="textarea" style="width: 90%;" :rows="3" placeholder="" disabled
							v-model="categoryProjectData.projectDescribe">
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>

			<h3 class="title">进度管理</h3>
			<el-row :gutter="20">
				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="是否预投入：" prop="yutouru">
						<el-select v-model="categoryProjectData.yutouru" placeholder="请选择" size="small" disabled>
							<el-option v-for="item in yutouruOption" :key="item.value" :label="item.text"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="当前阶段：" prop="projectStageName">
						<el-select v-model="categoryProjectData.projectStageName" placeholder="请选择" size="small"
							disabled>
							<el-option v-for="item in options" :key="item.dictKey" :label="item.dictValue"
								:value="item.dictKey">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="当前节点：" prop="projectNodeName">
						<el-select v-model="categoryProjectData.projectNodeName" placeholder="请选择" size="small"
							disabled>
							<el-option v-for="item in options" :key="item.dictKey" :label="item.dictValue"
								:value="item.dictKey">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="累计工时：" prop="toatlHour">
						<el-input type="number" maxlength="20" :rows="2" placeholder="请输入内容"
							v-model="categoryProjectData.toatlHour" size="small" disabled>
							<template slot="append">小时</template>
						</el-input>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="24" :lg="16">
					<el-form-item class="w-full" label="节点附件：" prop="nodeUrl">
						<el-upload class="upload-demo " style="display: flex;align-items: center;"
							action="/api/blade_shopmall/common/upload" disabled :file-list="nodeUrl"
							:on-preview="handlePreview">
						</el-upload>
					</el-form-item>
				</el-col>
			</el-row>

			<h3 class="title">成本管理</h3>
			<el-row :gutter="20">
				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="成本预算：" prop="projectedCost">
						<el-input type="number" maxlength="20" placeholder="请输入内容"
							v-model="categoryProjectData.projectedCost" size="small" disabled>
							<template slot="append">元</template>
						</el-input>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="实际人工成本：" >
						<el-input type="number" maxlength="20" placeholder="请输入内容"
							v-model="categoryProjectData.laborCost" size="small" disabled>
							<template slot="append">元</template>
						</el-input>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="实际支出：" prop="actualOutlay">
						<el-input type="number" maxlength="20" placeholder="请输入内容"
							v-model="categoryProjectData.actualOutlay" size="small" disabled>
							<template slot="append">元</template>
						</el-input>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="收入：" prop="income">
						<el-input type="number" maxlength="20" placeholder="请输入内容" v-model="categoryProjectData.income"
							size="small" disabled>
							<template slot="append">元</template>
						</el-input>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="合同额：" prop="contractAmount">
						<el-input type="number" maxlength="20" placeholder="请输入内容"
							v-model="categoryProjectData.contractAmount" size="small" disabled>
							<template slot="append">元</template>
						</el-input>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="毛利：">
						<el-input type="number" maxlength="20" placeholder="请输入内容"
							v-model="categoryProjectData.grossProfit" size="small" disabled>
							<template slot="append">元</template>
						</el-input>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="实际毛利：">
						<el-input type="number" maxlength="20" placeholder="请输入内容"
							v-model="categoryProjectData.actualGrossProfit" size="small" disabled>
							<template slot="append">元</template>
						</el-input>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6" v-if="[8, 9, 10].includes(projectNode)">
					<el-form-item class="w-full" label="收款：" prop="receivedAmount">
						<el-input type="number" maxlength="20" placeholder="请输入内容"
							v-model="categoryProjectData.receivedAmount" size="small" disabled>
							<template slot="append">元</template>
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row :gutter="20">
				<el-col :span="24" :md="12" :lg="12">
					<el-form-item class="w-full" label="概(预)算表：" prop="projectedUrl">
						<el-upload class="upload-demo" :on-preview="handlePreview" disabled
							style="display: flex;align-items: center;" action="/api/blade_shopmall/common/upload"
							:file-list="projectedUrl">
						</el-upload>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="12" v-if="[9, 10].includes(projectNode)">
					<el-form-item class="w-full" label="决算表：" prop="statement">
						<el-upload class="upload-demo" style="display: flex;align-items: center;"
							action="/api/blade_shopmall/common/upload" disabled :on-preview="handlePreview" :limit='1'
							:file-list="statement">
						</el-upload>
					</el-form-item>
				</el-col>
			</el-row>

			<h3 class="title">整体评价</h3>
			<el-row :gutter="20">
				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="进度：" prop="projectProgress">
						<el-select v-model="categoryProjectData.projectProgress" placeholder="请选择" size="small"
							disabled>
							<el-option v-for="item in projectProgressOption" :key="item.value" :label="item.text"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>

				<el-col :span="24" :md="12" :lg="8" :xl="6">
					<el-form-item class="w-full" label="成本：" >
						<el-input type="text" maxlength="20" placeholder="请输入内容" v-model="categoryProjectData.cost"
							size="small" disabled>
							<template slot="append">元</template>
						</el-input>
					</el-form-item>
				</el-col>

				<el-col :span="24">
					<el-form-item class="w-full" label="存在风险：">
						<el-input type="textarea" style="width: 90%;" :rows="3" placeholder="请输入内容"
							v-model="categoryProjectData.risk" disabled>
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>

		<span slot="footer" class="dialog-footer">
			<el-button @click="handleClose(1)" type="primary">审核通过</el-button>
			<el-button @click="handleClose(2)">驳 回</el-button>
		</span>
	</div>
</template>

<script>
import { projectRules } from '@/utils/projectsRules.js'
import { projectDetail, getDictList, nodeAudit } from '../../https/api.js';
export default {
	data() {
		return {
			rules: projectRules,
			req: {},
			options: [],
			textarea: '',
			categoryProjectData: {},
			tabsIndex: 0,
			projectedUrl: [],
			statement: [], //决算表
			nodeUrl: [],
			yutouruOption: [ //是否预算配置
				{
					value: 1,
					text: '是'
				},
				{
					value: 0,
					text: '否'
				}
			],
			theCurrentNodeList: [],
			projectProgressOption: [{
				value: 1,
				text: '正常'
			},
			{
				value: 2,
				text: '风险'
			},
			{
				value: 3,
				text: '滞后'
			}
			],
			projectNode: 0,
		}
	},
	props: {
		parameter: {
			type: [Object, Array],
		}
	},
	mounted() {
		this.tabsIndex = this.parameter.projectNode;
		this.projectNode = this.parameter.projectNode;
		this.projectDetail();
		this.theCurrentNode('project_node');
	},
	methods: {
		//当前节点
		async theCurrentNode(e) {
			const res = await getDictList({
				code: e
			})
			if (res.code == 200) {
				this.theCurrentNodeList = res.data
			}
		},
		//项目详情
		async projectDetail(e) {
			const res = await projectDetail(this.parameter)
			if (res.code == 200) {
				this.categoryProjectData = res.data
				res.data.projectedUrl.forEach((item, index) => {
					this.projectedUrl.push({
						name: item.fileName,
						url: item.fileUrl,
						id: item.id
					})
				})
				res.data.nodeUrl.forEach((item, index) => {
					this.nodeUrl.push({
						name: item.fileName,
						url: item.fileUrl,
						id: item.id
					})
				})
				res.data.statement.forEach((item, index) => {
					this.statement.push({
						name: item.fileName,
						url: item.fileUrl,
						id: item.id
					})
				})
			}
		},

		handleClose(e) {
			this.$confirm('是否确认？')
				.then(_ => {
					this.nodeAudit(e);
				})
				.catch(_ => { });
		},
		async nodeAudit(e) {
			const loading = this.$loading({
				lock: true,
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0)'
			});
			const res = await nodeAudit({
				auditResult: e,
				id: this.parameter.id
			})
			if (res.code == 200) {
				loading.close();
				this.$message.success(`成功！`);
				this.$emit('closeDialogAudit', false)
			} else {
				loading.close();
			}
		},
		postData(url, data, callback, token) {
			var xhr = new XMLHttpRequest();
			xhr.onreadystatechange = function () {
				if (xhr.readyState == 4) {
					if ((xhr.status >= 200 && xhr.status < 300) || xhr.status == 304) {
						console.log(xhr.response);
						callback && callback(xhr.response);
					}
				}
			}
			xhr.open('POST', url, true);
			xhr.responseType = 'blob';
			xhr.setRequestHeader('Content-Type', 'application/json');
			xhr.setRequestHeader('Accept', 'application/json');
			if (token) {
				xhr.setRequestHeader('X-Access-Token', token)
			}
			xhr.send(JSON.stringify(data)); // 必须是字符串形式
		},
		// 点击控制文件上传后的下载
		handlePreview(file) {
			console.log(file);
			if (file.id) {
				const th_is = this;
				var url = '/api/blade_shopmall/common/export';
				var params = {
					id: file.id
				};
				var callback = function (data) {
					console.log(data);
					if (data.size > 70) {
						let url = window.URL.createObjectURL(
							new Blob([data], {
								// type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
								// type: httpType
							})
						);
						//创建隐藏的a标签
						let link = document.createElement("a");
						link.style.display = "none";
						link.href = url;
						//设置导出文件的名字
						let excelName = `${th_is.categoryProjectData.projectCode}_${file.name}`;
						link.setAttribute("download", excelName);
						document.body.appendChild(link);
						//模拟点击事件
						link.click();
						//导出成功后删除这个标签并释放blob对象
						link.remove();
						window.URL.revokeObjectURL(url); //释放掉blob对象
						th_is.$message({
							message: '导出成功！',
							type: 'success'
						});
					} else {
						th_is.$message({
							message: '导出数据为空！',
							type: 'error'
						});
					}
				}
				var token = localStorage.getItem('GETTOKEN') || '';
				this.postData(url, params, callback, token);
			}
		},
		astrict() {
			this.$message.error(`超出最大可上传数量！`);
		},
	}
}
</script>

<style lang="less" scoped>
.content {
	.title {
		font-weight: 400;
		margin-bottom: 20px;
	}

	.haders_nav {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		h4 {
			font-weight: 400;
		}

		.el-input {
			width: 250px;
			// height:30px !important;
			// margin-left: 20px;
			margin-right: 20px;
		}

		.el-button {
			width: 100px;
		}
	}

	.demo-input-suffix /deep/ .el-select {
		width: 250px;
		margin-right: 20px;
	}

	.demo-input-suffix {
		margin-bottom: 20px;
		font-size: 10px;
	}

	.demo-input /deep/ .el-form-item__label {
		font-size: 10px;
	}

	.demo-input /deep/ .el-textarea {
		width: 50%;
	}

	.demo-input /deep/ .el-form-item__label {
		padding: 0px 4px 0px 0px;
	}

	.dialog-footer {
		display: flex;
		justify-content: flex-end;
	}

	::v-deep .el-upload-list__item.is-success.focusing .el-icon-close-tip {
		display: none !important;
	}
}
</style>
