<template>
	<div class="content">
		确认{{parameter.status==4?'挂起':parameter.status==5?'挂起复审':'废止审核'}}项目？
		<span  class="dialog-footer">
			<el-button @click="handleClose(1)" type="primary">审核通过</el-button>
			<el-button @click="handleClose(2)" >驳 回</el-button>
		</span>
	</div>
</template>

<script>
	import {
		handsAudit,
		handsReview,
		abolitionAudit
	} from '../../https/api.js';
	export default {
		data() {
			return {
				
			}
		},
		props:{
			parameter:{
				type:[Object,Array],
			}
		},
		mounted() {
			
		},
		methods:{
			async handleClose(e){
				const loading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0)'
				});
				if(this.parameter.status==4){//挂起初审
					const res = await handsAudit({
						auditResult:e,
						id:this.parameter.id
					})
					if(res.code==200){
						loading.close();
						this.$message.success(`操作成功！`);
						this.$emit('closeDialogApplication',false)
					}else{
						loading.close();
					}
				}else if(this.parameter.status==5){//挂起复审
					const res = await handsReview({
						auditResult:e,
						id:this.parameter.id
					})
					if(res.code==200){
						loading.close();
						this.$message.success(`操作成功！`);
						this.$emit('closeDialogApplication',false)
					}else{
						loading.close();
					}
				}else if(this.parameter.status==7){//废止审核
					const res = await abolitionAudit({
						auditResult:e,
						id:this.parameter.id
					})
					if(res.code==200){
						loading.close();
						this.$message.success(`操作成功！`);
						this.$emit('closeDialogApplication',false)
					}else{
						loading.close();
					}
				}
			},
		}
	}

</script>

<style lang="less" scoped>
	.content {
		font-size: 25px;
		.dialog-footer{
			display: flex;
			justify-content: flex-end;
		}
	}
</style>